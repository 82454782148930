<template>
    <div class="posts_all">
        <b-overlay :show="loading" variant="transperant" opacity="1" spinner-variant="primary">
            <b-card no-body>
                <b-card-header>
                    <b-card-title>Posts</b-card-title>
                </b-card-header>
                <div class="mb-2 mx-1">
                    <!-- Table Top -->
                    <b-row>
                        <!-- Per Page -->
                        <b-col cols="12" md="8" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                            <label>Show</label>
                            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="pageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
                            <label>entries</label>
                        </b-col>
                        <!-- Search -->
                        <b-col cols="12" md="4">
                            <div class="d-flex align-items-center justify-content-end">
                                <b-form-input v-model="filter" class="d-inline-block mr-1" placeholder="Search..." />
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <b-row>
                    <b-col cols="12">
                        <b-table id="all_posts_table" hover responsive class="position-relative" :per-page="perPage" :current-page="currentPage"
                            :items="myProvider" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection" :filter="filter" show-empty
                            empty-text="لم يتم العثور على سجلات مطابقة" >
                            <template #cell(post)="data">
                                <p style="white-space: pre;">{{ data.item.caption.substr(0, 100) }}</p>
                                <h5 class="text-primary">{{ data.item.title }}</h5>
                                <!-- <span v-if="data.item.type == 'normal'" class="d-inline-block m1">
                                    <div v-for="media, index in data.item.medias" :key="media.id"
                                        class="d-inline-block mr-25 mb-1 m1 border">
                                        <b-img v-if="index< 2" height="55" width="55" :src="media.media" rounded />
                                    </div>
                                    <div v-if="data.item.medias.length >2" class="d-inline-block mr-25 mb-1 m1">+{{ data.item.medias.length-2 }}</div>
                                </span>
                                <div v-else>
                                    <video v-for="media in data.item.medias" :key="media.id" autoplay="autoplay" style="width: 70px;">
                                        <source :src="media.media">
                                    </video>
                                </div> -->
                            </template>
                            <template #cell(channels)="data">
                                <b-avatar-group size="32px">
                                    <b-avatar v-for="channel in data.item.channels" :key="channel.id" v-b-tooltip.hover
                                        class="pull-up" :title="channel.channel.title" badge-variant="default"
                                        :src="channel.channel.image_url">
                                        <template #badge>
                                            <b-img width="11" rounded="" :src="getChannelTypeImage(channel.channel.type)"
                                                variant="default" />
                                        </template>
                                    </b-avatar>
                                </b-avatar-group>
                            </template>
                            <template #cell(status)="data">
                                <template v-if="data.item.status == 'progressed'">
                                    <b-badge pill class="mr-75" variant="light-warning">Progress</b-badge>
                                    <b-spinner variant="warning" small type="grow" />
                                </template>
                                <template v-if="data.item.status == 'posted'">
                                    <b-badge pill class="mr-75" variant="light-success">posted</b-badge>
                                </template>
                                <template v-if="data.item.status == 'scheduled'">
                                    <b-badge pill class="mr-75" variant="light-info">scheduled</b-badge>
                                </template>
                            </template>

                            <template #cell(created_at)="data">
                                <b-badge variant="light-secondary">
                                    <feather-icon icon="ClockIcon" class="mr-25" />
                                    <span>{{ moment(data.item.created_at).tz($store.state.auth.user.current_brand_timezone).format('llll') }}</span>
                                </b-badge>
                            </template>

                            <template #cell(published_at)="data">
                                <b-badge variant="light-success" v-if="data.item.published_at">
                                    <feather-icon icon="SendIcon" class="mr-25" />
                                    <span>{{ moment(data.item.published_at).tz($store.state.auth.user.current_brand_timezone).format('llll') }}</span>
                                </b-badge>
                            </template>

                            <template #cell(action)="data">
                                <div class="float-right">
                                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-danger"
                                        class="btn-icon mr-75" @click="$emit('deletePost', data.item.id)">
                                        <feather-icon icon="TrashIcon" />
                                    </b-button>
                                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary"
                                        class="btn-icon mr-75" @click="$emit('updatePost', data.item)">
                                        <feather-icon icon="SettingsIcon" />
                                    </b-button>
                                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary"
                                        class="btn-icon" @click="$emit('getInsights', data.item)">
                                        <feather-icon icon="InfoIcon" />
                                    </b-button>
                                </div>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>

                <b-row class="p-2">
                    <b-col cols="12">
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                            class="mb-0 mt-1 mt-sm-0" pills first-number last-number prev-class="next-item"
                            next-class="prev-item" />
                    </b-col>
                </b-row>
            </b-card>
        </b-overlay>
    </div>
</template>


<script>
import {
    BCard, BCardText, BRow, BCol, BButton, BAvatar, BLink, BBadge, BTabs, BTab, BMedia, BImg, BFormInput, BFormGroup, BForm,
    BOverlay, BFormCheckbox, BModal, BFormFile, BTable, BFormSelect, BPagination, BInputGroup, VBTooltip,
    BInputGroupAppend, BCardTitle, BCardHeader, BSpinner, BMediaAside, BMediaBody, BEmbed, BAvatarGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from 'axios'
export default {
    components: {
        BCard, BCardText, BRow, BCol, BButton,
        BLink, BAvatar, BBadge, BTabs, BTab, BMedia, BImg, BFormInput, BFormGroup, BForm, BOverlay,
        BModal, BFormFile, BTable, BFormSelect, BPagination, BInputGroup, BCardTitle, BCardHeader,
        BInputGroupAppend, BFormCheckbox, vSelect, BSpinner, BMediaAside, BMediaBody, BEmbed, BAvatarGroup
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
            overlayImage: false,
            addModalShow: false,
            loading: false,
            showLoader: true,
            perPage: 5,
            pageOptions: [5, 10, 20],
            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            fields: [
                { key: 'post', label: 'Post' },
                // { key: 'title', label: 'title' },
                // { key: 'caption', label: 'caption' },
                { key: 'channels', label: 'channels' },
                { key: 'status', label: 'status' },
                { key: 'published_at', label: 'published_at' },
                // { key: 'created_at', label: 'created at' },
                { key: 'action', label: '' },
            ],
            posts: []
        }
    },
    mounted() {
        // this.getPosts()
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        getChannelTypeImage(type) {
            if (type == "fb_page") return require('@/assets/images/channels/facebook.png')
            if (type == "instagram") return require('@/assets/images/channels/instagram.png')
            if (type == "twitter") return require('@/assets/images/channels/twitter-circle.png')
            if (type == "tiktok") return require('@/assets/images/channels/tiktok-circle.png')
            if (type == "linkedin_personal") return require('@/assets/images/channels/linkedin.png')
            if (type == "linkedin_company") return require('@/assets/images/channels/linkedin.png')
            if (type == "google_youtube") return require('@/assets/images/channels/youtube.png')
            if (type == "reddit") return require('@/assets/images/channels/reddit.png')
            if (type == "pinterest") return require('@/assets/images/channels/pinterest-circle.png')
            if (type == "google_business") return require('@/assets/images/channels/gmb.svg')
            if (type == "telegram") return require('@/assets/images/channels/telegram.png')
        },
        async getPosts() {
            this.loading = true
            await axios.get(`/posts?brand_id=${this.$store.state.auth.user.current_brand}&is_main=1&page_size=10`)
                .then(response => {
                    console.log(response);
                    this.posts = response.data.results
                    this.totalRows = response.data.results.length
                    setTimeout(() => this.loading = false, 500);
                })
                .catch(error => {
                    console.log(error);
                })
        },
        async myProvider(ctx) {
            this.loading = true
            try {
                let endpoint = `/posts?brand_id=${this.$store.state.auth.user.current_brand}&p=${ctx.currentPage}&is_main=1&page_size=${ctx.perPage}`
                if (ctx.filter != '' && ctx.filter != null){
                    endpoint = `/posts?brand_id=${this.$store.state.auth.user.current_brand}&p=${ctx.currentPage}&is_main=1&page_size=${ctx.perPage}&search=${ctx.filter}`
                }
                const response = await axios.get(endpoint)
                this.loading = false
                this.totalRows = response.data.count
                return response.data.results
            } catch (error) {
                this.loading = false
                return []
            }
        }
    }
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
<style>
.popular {
    border: 1px dashed var(--primary);
}
</style>