<template>
    <div>
        <b-overlay :show="loading" variant="transperant" opacity="1" spinner-variant="primary">
            <b-row class="blog-list-wrapper">
                <b-col
                    md="12"
                    cols="12"
                    v-for="post in posts"
                    :key="post.id"
                >
                    <b-card
                        tag="article"
                        no-body
                    >
                        <b-card-body>
                            <b-card-title>
                                <b-link class="blog-title-truncate text-body-heading">{{post.title}}</b-link>
                            </b-card-title>
                            <b-card-text class="blog-content-truncate">{{post.caption}}</b-card-text>
                            
                            <span v-if="post.type == 'normal'" class="d-inline-block m1">
                                <b-img
                                    v-for="media in post.medias" :key="media.id"
                                    height="55" width="55"
                                    :src="media.media"
                                    class="d-inline-block mr-25 mb-1 m1 border"
                                    rounded
                                />
                            </span>
                            <div v-else>
                                <b-embed v-for="media in post.medias" :key="media.id" controls type="video" aspect="21by9">
                                    <source :src="media.media">
                                </b-embed>
                            </div>
                            <div class="my-1 py-25">
                                <b-avatar-group size="32px">
                                    <b-avatar
                                        v-for="channel in post.channels"
                                        :key="channel.id"
                                        v-b-tooltip.hover
                                        class="pull-up"
                                        :title="channel.channel.title"
                                        badge-variant="default"
                                        :src="channel.channel.image_url"
                                    >
                                        <template #badge>
                                            <b-img width="11" rounded="" :src="getChannelTypeImage(channel.channel.type)" variant="default" />
                                        </template>
                                    </b-avatar>
                                </b-avatar-group>
                            </div>
                            <hr>
                            <div class="d-flex justify-content-between align-items-center">
                                <b-link>
                                    <div class="d-flex align-items-center text-body">
                                    <feather-icon
                                        icon="ClockIcon"
                                        class="mr-50"
                                    />
                                        <span class="font-weight-bold">at {{ moment(post.created_at).tz($store.state.auth.user.current_brand_timezone).format('llll') }}</span>
                                    </div>
                                </b-link>
                                <div class="font-weight-bold">
                                    <template v-if="post.status == 'progressed'">
                                        <b-badge pill class="mr-75" variant="light-warning">Progress</b-badge>
                                        <b-spinner variant="warning" small type="grow" />
                                    </template>

                                    <template v-else>
                                        <b-badge pill class="mr-75" variant="light-info">{{post.status}}</b-badge>
                                        <b-dropdown
                                            no-caret
                                            variant="link"
                                        >
                                            <template #button-content>
                                                <feather-icon
                                                    icon="MoreVerticalIcon"
                                                    size="16"
                                                    class="text-body align-middle mr-25"
                                                />
                                            </template>
                                            <b-dropdown-item @click="$emit('deletePost', post.id)">
                                                <feather-icon icon="TrashIcon" />
                                                <span class="align-middle ml-50">delete</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="$emit('updatePost', post)">
                                                <feather-icon icon="EditIcon" />
                                                <span class="align-middle ml-50">Edit</span>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </template>
                                </div>
                            </div>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
            
            <!-- pagination -->
            <div class="mt-3">
                <b-pagination
                    v-model="currentPage"
                    align="center"
                    :total-rows="posts_rows"
                    :per-page="perPage"
                    @change="handlePaginationChange"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                >
                    <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                    </template>
                </b-pagination>
            </div>
        </b-overlay>
    </div>
</template>


<script>
import {
    BRow, BCol, BCard, BFormInput, BCardText, BCardTitle, BMedia, BAvatar, BMediaAside, BMediaBody, BImg, BCardBody, BLink,
    BBadge, BFormGroup, BInputGroup, BInputGroupAppend, BPagination, BSpinner, BDropdown, BDropdownItem, BEmbed,
    BAvatarGroup, VBTooltip, BOverlay
} from 'bootstrap-vue'
import axios from 'axios'

export default {
    components: {
        BRow, BCol, BCard, BFormInput, BCardText, BCardTitle, BMedia, BAvatar, BMediaAside, BMediaBody, BImg, BCardBody, BLink,
        BBadge, BFormGroup, BInputGroup, BInputGroupAppend, BPagination, BSpinner, BDropdown, BDropdownItem, BEmbed,
        BAvatarGroup, VBTooltip, BOverlay
    },
    directives: {
        'b-tooltip': VBTooltip,
    },
    mounted(){
        this.getPosts()
    },
    data(){
        return {
            currentPage: 1,
            posts_rows: 0,
            perPage: 5,
            loading: true,
            posts: []
        }
    },
    methods: {
        async handlePaginationChange(value){
            this.currentPage = value
            await this.getPosts()
        },
        getChannelTypeImage(type){
            if (type == "fb_page") return require('@/assets/images/channels/facebook.png')
            if (type == "instagram") return require('@/assets/images/channels/instagram.png')
            if (type == "twitter") return require('@/assets/images/channels/twitter-circle.png')
            if (type == "tiktok") return require('@/assets/images/channels/tiktok-circle.png')
            if (type == "linkedin_personal") return require('@/assets/images/channels/linkedin.png')
            if (type == "linkedin_company") return require('@/assets/images/channels/linkedin.png')
            if (type == "google_youtube") return require('@/assets/images/channels/youtube.png')
            if (type == "reddit") return require('@/assets/images/channels/reddit.png')
            if (type == "pinterest") return require('@/assets/images/channels/pinterest-circle.png')
            if (type == "google_business") return require('@/assets/images/channels/gmb.svg')
            if (type == "telegram") return require('@/assets/images/channels/telegram.png')
        },
        async getPosts() {
            this.loading = true
            this.posts = []
            await axios.get(`/posts?brand_id=${this.$store.state.auth.user.current_brand}&p=${this.currentPage}&page_size=${this.perPage}&is_queue=1`)
                .then(response => {
                    console.log(response);
                    this.posts = response.data.results
                    this.posts_rows = response.data.count
                    this.loading = false
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
