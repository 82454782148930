<template>
    <div>
        <b-overlay :show="loading" variant="transperant" opacity="1" spinner-variant="primary">
            <b-row v-for="post in posts" :key="post.id" class="justify-content-md-center">
                    <b-col cols="12" xl="8" lg="8" md="8">
                        <b-overlay :show="postLoading.includes(post.id)" variant="transperant" opacity="1" spinner-variant="primary">
                            <b-card>
                                <div
                                    class="d-flex justify-content-start align-items-center mb-1"
                                >
                                    <!-- avatar -->
                                    <b-avatar
                                        :src="$store.state.auth.user.current_brand_image"
                                        size="50"
                                        class="mr-1"
                                    />
                                    <!--/ avatar -->
                                    <div class="profile-user-info">
                                        <h6 class="mb-0">{{$store.state.auth.user.current_brand_name}}</h6>
                                        <small class="text-muted">{{ $store.state.auth.user.current_brand_signature }}</small>
                                    </div>
                                    <div class="ml-auto">
                                        <b-dropdown
                                            no-caret
                                            variant="link"
                                        >
                                            <template #button-content>
                                                <feather-icon
                                                    icon="MoreVerticalIcon"
                                                    size="16"
                                                    class="text-body align-middle mr-25"
                                                />
                                            </template>
                                            <b-dropdown-item @click="$emit('updatePost', post)">
                                                <feather-icon icon="EditIcon" />
                                                <span class="align-middle ml-50">Edit</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="$emit('deletePost', post.id)">
                                                <feather-icon icon="TrashIcon" />
                                                <span class="align-middle ml-50">delete</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="$emit('getInsights', post)">
                                                <feather-icon icon="InfoIcon" />
                                                <span class="align-middle ml-50">Info</span>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </div>
                                <b-card-text style="white-space: pre;">{{post.caption}}</b-card-text>
                                
                                <b-row class="justify-content-md-center" v-if="post.channels.length > 0">
                                    <b-col cols="10" v-if="post.type == 'normal' && post.medias.length != 0">
                                        <b-carousel
                                            id="carousel-example-generic"
                                            controls
                                            indicators
                                            style="width: 100%; height: 400px;"
                                        >
                                            <b-carousel-slide v-for="media in post.medias" :key="media.id">
                                                <template #img>
                                                    <img
                                                        class="d-block img-fluid w-100"
                                                        :src="media.media"
                                                        alt="image slot"
                                                        style="width: 100%; height: 400px;"
                                                    >
                                                </template>
                                            </b-carousel-slide>
                                        </b-carousel>
                                    </b-col>
                                    <b-col cols="10" v-else>
                                        <!-- post video -->
                                        <div v-for="media in post.medias" :key="media.id">
                                            <b-embed controls type="video" aspect="4by3">
                                                <source :src="media.media">
                                            </b-embed>
                                        </div>
                                        <!--/ post video -->
                                    </b-col>
                                </b-row>
                                
                                <!-- post img -->
                                <!-- <b-img
                                    fluid
                                    rounded
                                    class="mb-25"
                                    src="https://demos.pixinvent.com/vuexy-vuejs-admin-template-vue2/demo-1/img/2.fedb99d5.jpg"
                                /> -->
                                <!--/ post img -->
                                <!-- post video -->
                                <!-- <b-embed
                                    type="iframe"
                                    src="data.postVid"
                                    allowfullscreen
                                    class="rounded mb-50"
                                /> -->
                                <!--/ post video -->

                                <!-- likes comments  share-->
                                <b-row class="pb-50 mt-50">
                                    <b-col
                                        sm="8"
                                        class="d-flex justify-content-between justify-content-sm-start mb-1"
                                    >
                                        <b-link class="d-flex align-items-center text-muted text-nowrap" @click="$emit('getInsights', post)">
                                            <feather-icon
                                                icon="ThumbsUpIcon"
                                                class="mr-50 profile-icon"
                                                size="18"
                                            />
                                            <span></span>
                                        </b-link>
                                        <div class="d-flex align-item-center">
                                            <b-avatar-group
                                                size="36"
                                                class="ml-1"
                                                v-if="post.channels.length > 1"
                                            >
                                                <b-avatar
                                                    badge-variant="default"
                                                    v-b-tooltip.hover
                                                    :title="channel.channel.title"
                                                    :src="channel.channel.image_url"
                                                    class="pull-up"
                                                    v-for="channel in post.channels" :key="channel.id"
                                                >
                                                    <template #badge>
                                                        <b-img width="13" rounded="" :src="getChannelTypeImage(channel.channel.type)" variant="default" />
                                                    </template>
                                                </b-avatar>
                                            </b-avatar-group>
                                            <b-avatar
                                                v-else-if="post.channels.length != 0"
                                                button
                                                badge-variant="default"
                                                v-b-tooltip.hover
                                                :title="post.channels[0].channel.title"
                                                :src="post.channels[0].channel.image_url"
                                                class="pull-up ml-1"
                                            >
                                                <template #badge>
                                                    <b-img width="13" rounded="" :src="getChannelTypeImage(post.channels[0].channel.type)" variant="default" />
                                                </template>
                                            </b-avatar>
                                            <b-dropdown
                                                no-caret
                                                variant="link"
                                                :ref="'dropdown-'+post.id"
                                            >
                                                <template #button-content>
                                                    <b-link @mouseenter="openChannels('dropdown-'+post.id)" class="text-muted text-nowrap mt-50 ml-50">channels</b-link>
                                                </template>
                                                <b-dropdown-item v-for="channel in post.channels" :key="channel.id" :href="channel.post_url" target="_blank">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div
                                                            class="d-flex justify-content-start align-items-center"
                                                        >
                                                            <b-avatar
                                                                :src="channel.channel.image_url"
                                                                badge-variant="default"
                                                                width="30"
                                                                alt="placeholder"
                                                                class="mr-2"
                                                            >
                                                                <template #badge>
                                                                    <b-img width="13" rounded="" :src="getChannelTypeImage(channel.channel.type)" variant="default" />
                                                                </template>
                                                            </b-avatar>
                                                            <div class="user-page-info">
                                                                <h6 class="mb-0">{{channel.channel.title}}</h6>
                                                                <small class="text-muted">{{channel.channel.username}}</small>
                                                            </div>
                                                        </div>
                                                        <div class="ml-3">
                                                            <b-badge
                                                                :variant="channel.status == 'posted' ? 'light-success' : channel.status == 'failed' ? 'light-danger' : channel.status == 'progressed' ? 'light-warning':'light-info'"
                                                            >{{ channel.status }}</b-badge>
                                                        </div>
                                                    </div>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </b-col>
                                    <b-col
                                        sm="4"
                                        class="d-flex justify-content-between justify-content-sm-end align-items-center mb-1"
                                    >
                                        <b-link @click="getComments(post)" class="text-body text-nowrap">
                                            <feather-icon
                                                icon="MessageSquareIcon"
                                                size="18"
                                                class="profile-icon mr-50"
                                            />
                                            <span class="text-muted mr-1" v-text="post.comments.length == 0 ? 'comments' : `${post.comments.length} comments`"></span>
                                        </b-link>
                                        <b-link class="text-body text-nowrap" @click="$emit('getInsights', post)">
                                            <feather-icon
                                                icon="Share2Icon"
                                                size="18"
                                                class="profile-icon mr-50"
                                            />
                                            <span class="text-muted"></span>
                                        </b-link>
                                    </b-col>
                                </b-row>
                                <!--/ likes comments  share-->

                                <template v-if="display_comments == post.id">
                                    <b-tabs>
                                        <b-tab lazy v-for="channel in post.channels" :key="channel.id" @click="setComment(post, channel)">
                                            <template #title>
                                                <b-avatar
                                                    badge-variant="default"
                                                    v-b-tooltip.hover
                                                    :title="channel.channel.title"
                                                    :src="channel.channel.image_url"
                                                    size="32"
                                                >
                                                    <template #badge>
                                                        <b-img width="10" rounded="" :src="getChannelTypeImage(channel.channel.type)" variant="default" />
                                                    </template>
                                                </b-avatar>
                                            </template>
                                            <!-- comments -->
                                            <!-- <template v-if="['fb_page', 'twitter', 'intagram', 'reddit', 'youtube', 'linkedin_personal', 'linkedin_company'].includes(channel.channel.type)"> -->
                                            <div v-for="comment in post.comments" :key="comment.id">
                                                <template v-if="comment.channel_id == channel.channel.id">
                                                    <div class="d-flex align-items-start mb-2">
                                                        <b-avatar
                                                            :src="comment.image_url"
                                                            size="34"
                                                            class="mt-25 mr-75"
                                                        />
                                                        <div class="profile-user-info w-100">
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <h6 class="mb-0">{{comment.name}}</h6>
                                                                <div>
                                                                    <b-link @click="setReply(post.id, channel.id, comment.id, comment)" class="text-body">
                                                                        <feather-icon
                                                                            icon="CornerUpLeftIcon"
                                                                            size="18"
                                                                            class="mr-25 profile-likes text-primary"
                                                                        />
                                                                    </b-link>
                                                                    <b-link @click="deleteComment(comment, channel.id, post.id)">
                                                                        <feather-icon
                                                                            icon="TrashIcon"
                                                                            size="18"
                                                                            class="ml-25 text-danger"
                                                                        />
                                                                    </b-link>
                                                                </div>
                                                            </div>
                                                            <small v-html="comment.message"></small>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-start ml-3 mb-1" v-for="reply in comment.replies" :key="reply.id">
                                                        <b-avatar
                                                            :src="reply.image_url"
                                                            size="34"
                                                            class="mt-25 mr-75"
                                                        />
                                                        <div class="profile-user-info w-100">
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <h6 class="mb-0">{{ reply.name }}</h6>
                                                                <b-link @click="deleteComment(reply, channel.id, post.id)">
                                                                    <feather-icon
                                                                        icon="TrashIcon"
                                                                        size="18"
                                                                        class="ml-25 text-danger"
                                                                    />
                                                                </b-link>
                                                            </div>
                                                            <small v-html="reply.message"></small>
                                                        </div>
                                                    </div>
                                                </template>
                                            </div>
                                            <!-- </template> -->
                                            <!-- <template v-else>Not Available for this channel</template> -->
                                            <!--/ comments -->
                                        </b-tab>
                                    </b-tabs>
                                    <!-- comment box -->
                                    <!-- <hr v-if="comment.parent.id != null"> -->
                                    <div class="d-flex align-items-center justify-content-center mb-1 mt-2" v-if="comment.parent.id != null">
                                        <div class="text-body text-nowrap">
                                            <feather-icon
                                                icon="CornerUpLeftIcon"
                                                size="18"
                                                class="profile-icon mr-50 text-primary"
                                            />
                                            <!-- <span class="mr-1 text-primary">reply to</span> -->
                                        </div>
                                        <b-avatar
                                            :src="comment.parent.image_url"
                                            size="34"
                                            class="mt-25 mr-75"
                                        />
                                        <div class="profile-user-info w-100">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h6 class="mb-0">{{comment.parent.name}}</h6>
                                            </div>
                                            <small v-html="comment.parent.comment.substr(0, 70)"></small>
                                        </div>
                                        <b-link class="text-body text-nowrap" @click="cancelReply">
                                            <feather-icon
                                                icon="XIcon"
                                                size="18"
                                                class="profile-icon text-muted mr-50"
                                            />
                                            <span class="text-muted mr-1">cancel</span>
                                        </b-link>
                                    </div>

                                    <div v-else class="mb-75">
                                        <b-link class="text-body text-nowrap mb-75">
                                            <feather-icon
                                                icon="MessageCircleIcon"
                                                size="18"
                                                class="profile-icon text-primary mr-50"
                                            />
                                            <span class="text-primary mr-1">comment on current channel</span>
                                        </b-link>
                                    </div>

                                    <b-form-group>
                                        <b-form-textarea
                                            rows="3"
                                            placeholder="Add Comment"
                                            v-model="comment.comment"
                                        />
                                    </b-form-group>
                                    <!--/ comment box -->

                                    <b-button
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                        size="sm"
                                        variant="primary"
                                        @click="processComment"
                                        :disabled="comment.comment == '' || comment.post_channel_id == null"
                                    >
                                        Post Comment
                                    </b-button>
                                </template>
                            </b-card>
                        </b-overlay>
                    </b-col>
            </b-row>
            <!-- pagination -->
            <div class="mt-3">
                <b-pagination
                    v-model="currentPage"
                    align="center"
                    :total-rows="posts_rows"
                    :per-page="perPage"
                    @change="handlePaginationChange"
                    first-number
                    last-number
                    prev-class="prev-item"
                    next-class="next-item"
                >
                    <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                    </template>
                </b-pagination>
            </div>
        </b-overlay>
    </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BAvatar, BCard, BCardText, BImg, BLink, BRow, BCol, BAvatarGroup, VBTooltip, BFormTextarea, BButton, BFormGroup, BEmbed,
  BDropdown, BDropdownItem, BCarousel, BCarouselSlide, BTabs, BTab, BOverlay, BBadge, BPagination
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
export default {
    components:{
        ToastificationContent, BAvatar, BCard, BCardText, BImg, BLink, BRow, BCol, BAvatarGroup, VBTooltip, BFormTextarea, BButton, BFormGroup, BEmbed,
        BDropdown, BDropdownItem, BCarousel, BCarouselSlide, BTabs, BTab, BOverlay, BBadge, BPagination
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple
    },
    data(){
        return {
            currentPage: 1,
            posts_rows: 0,
            perPage: 5,
            loading: true,
            postLoading: [],
            posts: [],
            comment: {
                comment: '',
                parent:{
                    id: null,
                    name: '',
                    comment: '',
                    image_url: ''
                },
                parent_id: null,
                channel_id: null,
                post_channel_id: null,
                post_id: null
            },
            comments: [],
            display_comments: null
        }
    },
    mounted(){
        this.getPosts()
    },
    methods: {
        async handlePaginationChange(value){
            this.currentPage = value
            await this.getPosts()
        },
        openChannels(ref){
            this.$refs[ref][0].show()
        },
        getChannelComments(id){
            console.log(id);
            var data = []
            for(let i=0; i<this.comments.length; i++){
                console.log(this.comments[i]['channel_id']);
                if (this.comments[i]['channel_id'] == id){
                    data.push(this.comments[i])
                }
            }
            return data
        },
        setComment(post, post_channel){
            this.comment.post_id = post.id
            this.comment.parent_id = null
            this.comment.post_channel_id = post_channel.id
        },
        cancelReply(){
            this.comment.parent.id = null
            this.comment.parent.name = null
            this.comment.parent.comment = null
            this.comment.parent.image_url = null
            this.comment.parent_id = null
            this.comment.post_channel_id = null
            this.comment.post_id = null
        },
        setReply(post_id, post_channel_id, parent_id, comment){
            this.comment.parent.id = comment.id
            this.comment.parent.name = comment.name
            this.comment.parent.comment = comment.message
            this.comment.parent.image_url = comment.image_url
            this.comment.parent_id = parent_id
            this.comment.post_channel_id = post_channel_id
            this.comment.post_id = post_id
        },
        deleteComment(comment, post_channel_id, post_id){
            console.log(comment);
            this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    var data;
                    if (comment.channel_type == 'linkedin') {
                        data = {
                            comment_id: comment.linkedin_id,
                            post_channel_id: post_channel_id
                        }
                    } else {
                        data = {
                            comment_id: comment.id,
                            post_channel_id: post_channel_id
                        }
                    }
                    console.log(data);
                    axios.post(`/posts/${post_id}/delete_comment/`, data)
                    .then(response => {
                        console.log(response);
                        this.$swal({
                            icon: 'success',
                            title: 'Deleted!',
                            text: 'Your file has been deleted.',
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                    })
                    .catch(error => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Warning !',
                                icon: 'AlertCircleIcon',
                                text: 'Something Went Wrong.',
                                variant: 'danger',
                            },
                        })
                        console.log(error);
                    })  
                }
            })
        },
        processComment(){
            if (this.comment.parent_id == null){
                this.postComment()
            } else {
                this.replyComment()
            }
        },
        replyComment(){
            const data = {
                comment: this.comment.comment,
                comment_id: this.comment.parent_id,
                post_channel_id: this.comment.post_channel_id
            }
            axios.post(`/posts/${this.comment.post_id}/reply_comment/`, data)
            .then(response => {
                console.log(response);
                    if (response.data.code == 201){
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Comment created',
                                icon: 'CheckIcon',
                                text: 'Comment was created successfully.',
                                variant: 'success',
                            },
                        })
                        console.log(response);
                        this.comment.comment = ''
                        this.cancelReply()
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Upgrade Plan!',
                                icon: 'AlertCircleIcon',
                                text: 'Comments quota exceeded, please upgrade plan.',
                                variant: 'danger',
                            },
                        })
                    }
            })
            .catch(error => {
                this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Warning !',
                            icon: 'AlertCircleIcon',
                            text: 'Something Went Wrong.',
                            variant: 'danger',
                        },
                    })
                console.log(error);
            })  
        },
        postComment(){
            if (this.comment == '' || this.comment.post_channel_id == null || this.comment.post_id == null){
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Warning !',
                        icon: 'AlertCircleIcon',
                        text: 'Please comment fields required.',
                        variant: 'danger',
                    },
                })
            } else {
                const data = {
                    comment: this.comment.comment,
                    post_channel_id: this.comment.post_channel_id
                }
                axios.post(`/posts/${this.comment.post_id}/post_comment/`, data)
                .then(response => {
                    console.log(response);
                    if (response.data.code == 201){
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Comment created',
                                icon: 'CheckIcon',
                                text: 'Comment was created successfully.',
                                variant: 'success',
                            },
                        })
                        console.log(response);
                        this.comment.comment = ''
                        this.cancelReply()
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Upgrade Plan!',
                                icon: 'AlertCircleIcon',
                                text: 'Comments quota exceeded, please upgrade plan.',
                                variant: 'danger',
                            },
                        })
                    }
                })
                .catch(error => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Warning !',
                            icon: 'AlertCircleIcon',
                            text: 'Something Went Wrong.',
                            variant: 'danger',
                        },
                    })
                    console.log(error);
                })
            }
        },
        async getComments(post){
            console.log(post);
            this.setComment(post, post.channels[0])
            this.postLoading.push(post.id)
            await axios.get(`/posts/${post.id}/comments/`)
            .then(response => {
                console.log(response);
                this.posts.forEach(item => {
                    if (item.id == post.id) {
                        item.comments = response.data
                    }
                })
                this.comment.comment = ''
                this.display_comments = post.id
                this.postLoading.pop(post.id)
            })
            .catch(error => {
                this.postLoading.pop(post.id)
                console.log(error);
            })
        },
        getChannelTypeImage(type){
            if (type == "fb_page") return require('@/assets/images/channels/facebook.png')
            if (type == "instagram") return require('@/assets/images/channels/instagram.png')
            if (type == "twitter") return require('@/assets/images/channels/twitter-circle.png')
            if (type == "tiktok") return require('@/assets/images/channels/tiktok-circle.png')
            if (type == "linkedin_personal") return require('@/assets/images/channels/linkedin.png')
            if (type == "linkedin_company") return require('@/assets/images/channels/linkedin.png')
            if (type == "google_youtube") return require('@/assets/images/channels/youtube.png')
            if (type == "reddit") return require('@/assets/images/channels/reddit.png')
            if (type == "pinterest") return require('@/assets/images/channels/pinterest-circle.png')
            if (type == "google_business") return require('@/assets/images/channels/gmb.svg')
            if (type == "telegram") return require('@/assets/images/channels/telegram.png')
        },
        async getPosts() {
            this.loading = true
            this.posts = []
            await axios.get(`/posts?brand_id=${this.$store.state.auth.user.current_brand}&status=posted&p=${this.currentPage}`)
                .then(response => {
                    console.log(response);
                    this.posts = response.data.results
                    this.totalRows = response.data.results.length
                    this.posts_rows = response.data.count
                    this.loading = false
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
